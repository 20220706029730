export default {
  tradeHistory: {
    buy: 'Buy',
    fee: 'Fee',
    time: 'Time',
    type: 'Type',
    side: 'Side',
    spot: 'Spot',
    sell: 'Sell',
    event: 'Event',
    filter: 'Filter',
    payout: 'Payout',
    market: 'Market',
    amount: 'Amount',
    address: 'Address',
    tradeId: 'Trade ID',
    timestamp: 'Timestamp',
    timeRange: 'Time range',
    allMarkets: 'All markets',
    derivatives: 'Derivatives',
    blockHeight: 'Block height',
    tradeHistory: 'Trade history',
    clearFilters: 'Clear filters',
    feeRecipient: 'Fee recipient',
    subaccountId: 'Subaccount ID',
    filterByAddress: 'Filter by address',
    tradeHistoryDetails: 'Trade history details'
  }
}
