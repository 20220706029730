export default {
  spendPool: {
    title: 'Community Spend Pool/Distribution Module',
    to: 'To CSP',
    from: 'From CSP/DM',
    enterAddress: 'Enter Address',
    timeRange: 'Time range (optional)',
    communitySpendPool: 'Community Spend Pool',
    cspDistributionModule: 'CSP/Distribution Module',
    enterAddressToSeeResults: 'Please enter an Injective address to see results'
  }
}
