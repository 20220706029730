export default {
  params: {
    price: 'Price',
    supply: 'Supply',
    height: 'Height',
    chainData: 'Chain Data',
    totalStake: 'Total Stake',
    blockTime: 'Block time',
    stakingApr: 'Staking APR',
    totalStakers: 'Total Stakers',
    inflationRate: 'Inflation Rate',
    totalBurned: 'Total Burned',
    transactionsCount: 'Transactions Count'
  }
}
