import { I18nMessageFunction } from './../../../types'

export default {
  account: {
    cw20: 'CW',
    ibc: 'IBC',
    bank: 'Bank',
    total: 'Total',
    value: 'Value',
    amount: 'Amount',
    native: 'Native',
    staked: 'Staked',
    tokens: 'Tokens',
    reward: 'Reward',
    address: 'Address',
    balance: 'Balance',
    holding: 'Holding',
    staking: 'Staking',
    unknown: 'Unknown',
    balances: 'Balances',
    cosmwasm: 'CosmWasm',
    holdings: 'Holdings',
    unstaked: 'Unstaked',
    usdTotal: 'USD Total',
    validator: 'Validator',
    allAssets: 'All Assets',
    assetType: 'Asset Type',
    unbondings: 'Unbondings',
    totalValue: 'Total value',
    details: 'Address details',
    delegations: 'Delegations',
    bridgedERC20: 'Bridged ERC20',
    cw20Balances: 'CW20 Balances',
    pathToOrigin: 'Path to origin',
    redelegations: 'Redelegations',
    insuranceFund: 'Insurance Fund',
    unrealizedPnl: 'Unrealized PnL',
    inUseReserved: 'In Use/Reserved',
    tradingAccount: 'Trading Account',
    unknownNetwork: 'Unknown Network',
    contractAddress: 'Contract Address',
    sourceValidator: 'Source Validator',
    whatDoesItMean: 'What does it mean?',
    totalInjBalance: 'Total INJ Balance',
    availableBalance: 'Available Balance',
    claimableRewards: 'Claimable Rewards',
    completitionTime: 'Completition Time',
    notFoundDescription: 'Please try again.',
    latestTransactions: 'Latest Transactions',
    noUnbondingsFound: 'No Unbondings Found.',
    insuranceFundToken: 'Insurance Fund Token',
    noDelegationsFound: 'No Delegations Found.',
    destinationValidator: 'Destination Validator',
    noTransactionsFound: 'No transactions found.',
    noRedelegationsFound: 'No Redelegations Found.',
    tradingAccountBalances: 'Trading Account Balances',
    isBalanceBreakdownEnabled: 'Include balance breakdown',
    invalidDescription: 'Please re-enter the account address correctly.',
    invalidTitle: 'Oops! The following account address format is incorrect:',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, We are unable to locate this account on ${named('network')}:`,
    ibcAssetPathDisplay:
      'The IBC assets have an unique way to display the origin and transfer paths of the assets.',
    totalIncludes:
      'Total includes Margin and Unrealized PnL from open positions as well as open orders.',
    totalValueIncludes:
      'Total value includes Margin and Unrealized PnL from open positions as well as open orders and staked amount. Keep in mind that Positions and Unrealized PnL are loaded in the background.',
    verified: 'Verified',
    internalVerification: 'Internally Verified',
    externalVerification: 'Externally Verified',
    unverified: 'Unverified',
    tokenFactory: 'Token Factory'
  }
}
