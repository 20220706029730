import { I18nMessageFunction, DappCategory } from './../../../types'
export default {
  featuredDapps: {
    [DappCategory.Dex]: 'DEX',
    [DappCategory.DeFi]: 'DeFi',
    [DappCategory.Tools]: 'Tools',
    [DappCategory.NFTFi]: 'NFTFi',
    here: ' here.',
    viewDapps: 'View dApps',
    allCategory: 'All Category',
    socialMedia: 'Social Media',
    featuredDapps: 'Featured dApps',
    buildOnInjective: 'Build on Injective',
    exploreDapps: 'Explore Injective dApps',
    injectiveContract: 'Injective Contract',
    submitYourOwnProject: 'Submit your own project',
    dappsExplained:
      'Injective has built the largest and fastest growing Web3 financial ecosystem, explore the featured builders working together to create a truly free and inclusive financial world.',
    openDapp: ({ named }: I18nMessageFunction) => `Open ${named('name')}`
  }
}
