/* eslint-disable no-console */
import {
  ErrorType,
  ThrownException,
  isThrownException,
  GRPC_REQUEST_FAILED,
  TransactionException,
  UnspecifiedErrorCode,
  GrpcUnaryRequestException,
  formatNotificationDescription
} from '@injectivelabs/exceptions'
import { StatusCodes } from 'http-status-codes'
import { type NotificationOptions } from '@shared/types'
import { BUGSNAG_KEY } from '@shared/utils/constant'
import { AccountFormatInvalidException } from '@/app/exceptions/AccountFormatInvalidException'
import { AccountNotFoundException } from '@/app/exceptions/AccountNotFoundException'
import { BlockNotFoundException } from '@/app/exceptions/BlockNotFoundException'
import { ContractNotFoundException } from '@/app/exceptions/ContractNotFoundException'
import { TransactionNotFoundException } from '@/app/exceptions/TransactionNotFoundException'
import { WasmCodeNotFoundException } from '@/app/exceptions/WasmCodeNotFoundException'
import { SearchStatusType } from '@/types/enum'
import { defineNuxtPlugin } from '#imports'
import { IS_PRODUCTION } from '@/app/utils/constant'

/**
 * As we conditionally include the nuxt-bugsnag module
 * the type of it can be undefined
 **/
declare let useBugsnag: () => any

export const isNotFoundExceptionException = (error: Error): boolean => {
  return (
    error instanceof BlockNotFoundException ||
    error instanceof ContractNotFoundException ||
    error instanceof WasmCodeNotFoundException ||
    error instanceof TransactionNotFoundException ||
    error instanceof AccountNotFoundException ||
    error instanceof AccountFormatInvalidException ||
    error instanceof AccountNotFoundException
  )
}

const handleNotFoundException = (error: Error) => {
  const appStore = useAppStore()
  const route = useRoute()

  if (error instanceof BlockNotFoundException) {
    appStore.$patch({
      searchStatus: SearchStatusType.BlockNotFound
    })

    return navigateTo({
      name: '404',
      params: { block: route.params.block }
    })
  }

  if (error instanceof ContractNotFoundException) {
    appStore.$patch({
      searchStatus: SearchStatusType.ContractNotFound
    })

    return navigateTo({
      name: '404',
      params: { contract: route.params.contract }
    })
  }

  if (error instanceof WasmCodeNotFoundException) {
    appStore.$patch({
      searchStatus: SearchStatusType.WasmCodeNotFound
    })

    return navigateTo({
      name: '404',
      params: { code: route.params.code }
    })
  }

  if (error instanceof TransactionNotFoundException) {
    appStore.$patch({
      searchStatus: SearchStatusType.TransactionNotFound
    })

    return navigateTo({
      name: '404',
      params: { transaction: route.params.transaction }
    })
  }

  if (
    error instanceof AccountNotFoundException ||
    error instanceof AccountFormatInvalidException
  ) {
    const searchStatus =
      error instanceof AccountNotFoundException
        ? SearchStatusType.AccountNotFound
        : SearchStatusType.AccountInvalid

    appStore.$patch({
      searchStatus
    })

    return navigateTo({
      name: '404',
      params: { account: route.params.transaction }
    })
  }
}

const reportToUser = (error: ThrownException) => {
  handleNotFoundException(error)

  const notificationStore = useSharedNotificationStore()

  // Timedout requests happening in the background should not be reported to the user
  if (
    error.type === ErrorType.HttpRequest &&
    error.code === StatusCodes.REQUEST_TOO_LONG
  ) {
    return
  }

  const shouldIgnoreToast =
    error.code === UnspecifiedErrorCode &&
    error.contextCode !== GRPC_REQUEST_FAILED

  if (shouldIgnoreToast) {
    return
  }

  if (error instanceof GrpcUnaryRequestException) {
    return notificationStore.error({
      title: 'The product is experiencing higher than usual demand',
      description:
        'Hang tight, engineers are doing their best to improve the performance and efficiency.'
    })
  }

  if (!(error instanceof TransactionException)) {
    return notificationStore.error({
      title: error.message || 'Something happened'
    })
  }

  const { tooltip, description } = formatNotificationDescription(
    error.originalMessage
  )

  notificationStore.error({
    title: error.message || 'Something happened',
    description,
    tooltip
  } as NotificationOptions)
}

const reportToBugSnag = (error: ThrownException) => {
  if (!IS_PRODUCTION) {
    console.warn(error.toCompactError().message)
    console.error(error)

    return
  }

  if ([ErrorType.Unspecified, ErrorType.WalletError].includes(error.type)) {
    console.warn(error.toCompactError().message)
    console.error(error)
  }

  if (BUGSNAG_KEY) {
    useBugsnag().notify(error, (event: any) => {
      event.errors.forEach((e: any) => {
        e.errorClass = error.errorClass || error.name || error.constructor.name
      })

      event.addMetadata('error-context', error.toObject())
    })
  }
}

const reportUnknownErrorToBugsnag = (error: Error) => {
  if (!IS_PRODUCTION) {
    console.error({ error, stack: error.stack })
  }

  const newError = new Error(
    `The ${error.message} is not handled as an Exception - ${error.stack}`
  )

  console.warn(newError.message, newError.stack)
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.warn(error, context, (error as any).stack)
  }

  window.onunhandledrejection = function (event: PromiseRejectionEvent) {
    const error = event.reason

    if (!IS_PRODUCTION) {
      return
    }

    if (!isThrownException(error)) {
      reportUnknownErrorToBugsnag(error)
    } else {
      reportToBugSnag(error)
    }
  }

  const errorHandler = (error: ThrownException) => {
    if (!isThrownException(error) && !isNotFoundExceptionException(error)) {
      return reportUnknownErrorToBugsnag(error)
    }

    if (isNotFoundExceptionException(error)) {
      return handleNotFoundException(error)
    }

    reportToUser(error)

    if (IS_PRODUCTION) {
      reportToBugSnag(error)
    }

    console.warn(error.toObject())
  }

  return {
    provide: {
      onError: errorHandler
    }
  }
})
