import { I18nMessageFunction } from './../../../types'

export default {
  markets: {
    allMarkets: 'All Markets',
    allTypes: 'All Types',
    baseDenom: 'Base Denom',
    baseMakerFee: 'Base Maker Fee',
    baseTakerFee: 'Base Taker Fee',
    derivative: 'Derivative',
    estimatedFundingRate: 'Estimated Funding Rate',
    exchangeRelayers: 'Exchanges / Relayers',
    fundingInterval: 'Funding Interval',
    fundingRate: 'Funding Rate',
    fundingRateHistory: 'Funding Rate History',
    fundingRateTimestamp: ({ named }: I18nMessageFunction) =>
      `${named('formattedTimestamp')}`,
    hourlyInterestRate: 'Hourly Interest Rate',
    includeLowVolumeMarkets: 'Include low volume markets',
    initialMarginRatio: 'Initial Margin Ratio',
    lastFundingRate: 'Last Funding Rate',
    lastTwelveHours: 'Last 12 hours',
    lastTwentyFourHours: 'Last 24 hours',
    maintenanceMarginRatio: 'Maintenance Margin Ratio',
    minNotional: 'Min. Notional',
    makerFeeRate: 'Maker Fee Rate',
    market: 'Market',
    marketId: 'Market Id',
    marketsInfo: 'Markets Info',
    marketType: 'Market Type',
    minAmountMovement: 'Min. Amount Movement',
    minPriceMovement: 'Min. Price Movement',
    minPriceTickSize: 'Min. Price Tick Size',
    minQuantityTickSize: 'Min. Quantity Tick Size',
    nextFunding: 'Next Funding',
    oracleBase: 'Oracle Base',
    oracleQuote: 'Oracle Quote',
    oracleType: 'Oracle Type',
    oracleScaleFactor: 'Oracle Scale Factor',
    perDay: 'per day',
    price: 'Price',
    proposalId: 'Proposal ID',
    quoteDenom: 'Quote Denom',
    realtimeFundingRate: 'Real-Time Funding Rate',
    searchMarket: 'Search Market',
    spot: 'Spot',
    takerFeeRate: 'Taker Fee Rate',
    ticker: 'Ticker',
    time: 'Time',
    tradingRules: 'Trading Rules',
    type: 'Type',
    usd: 'USD',
    volume: 'Volume (24H)',
    oneHour: '1 hr',
    futures: 'Futures'
  }
}
