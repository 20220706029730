import { default as _4044eEP6GkEjXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/404.vue?macro=true";
import { default as cw20balancesVbaajXtdDLMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/cw20balances.vue?macro=true";
import { default as indexvXyVz5aRdoMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as indexEUZSMbqG18Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as redelegationsGWFq1Fg6BvMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/redelegations.vue?macro=true";
import { default as unbondings0kVDwAIjyJMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unbondings.vue?macro=true";
import { default as stakingGwL6xUvCDPMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsjzG5jK9tBhMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93h20OclfX44Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue?macro=true";
import { default as assettkt5EzHwRUMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/asset.vue?macro=true";
import { default as indexhs0SzgvDW3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contractsvF84AH4K8aMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assets5JxONUhLURMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93ND4OLRWCUwMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksZwwtmm0olXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93NM6XvLsoXVMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue?macro=true";
import { default as codesueRhjKMO3LMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/codes.vue?macro=true";
import { default as community_45spend_45poolANt69RLQI6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as balancesVqR2wh4RcuMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesBvX8g4Hy1FMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexCynXTUk3NKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init9ehBsV5hSgMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as token_45holdersYIa6oGcJMpMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93vTnbrrqMqsMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as contractstUQo6K8X0mMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contracts.vue?macro=true";
import { default as env4srMXunChyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/env.vue?macro=true";
import { default as featured_45dappsS33etJ0izDMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/featured-dapps.vue?macro=true";
import { default as indexlDRZUe1rLcMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/index.vue?macro=true";
import { default as maintenance46QiZtYHjXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue?macro=true";
import { default as funding_45rate_45historyrEsfQCTqhyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexD2h1ame7B6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateDQiAJpsMCEMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as _91marketId_93p6RU1ur5zMMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue?macro=true";
import { default as trading_45ruleseT2u2LGpWiMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsjMVx5f8CF2Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue?macro=true";
import { default as raw_45txhsZgX3k56WMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as _91tradeId_932IZbZckUG3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexwUMH3BoS4wMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as index5cBaxeZBEYMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93Il44fywLkaMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexOtUprGVh8TMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45historyITNpc6K06dMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsJQMRIBwcOKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexjAJ4CcZarrMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93vWk3ruUw4qMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsf9smiZHihmMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/404.vue")
  },
  {
    name: _91account_93h20OclfX44Meta?.name,
    path: "/account/:account()",
    meta: _91account_93h20OclfX44Meta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue"),
    children: [
  {
    name: "account-account-cw20balances",
    path: "cw20balances",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/cw20balances.vue")
  },
  {
    name: "account-account",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue")
  },
  {
    name: stakingGwL6xUvCDPMeta?.name,
    path: "staking",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue"),
    children: [
  {
    name: "account-account-staking",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue")
  },
  {
    name: "account-account-staking-redelegations",
    path: "redelegations",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/redelegations.vue")
  },
  {
    name: "account-account-staking-unbondings",
    path: "unbondings",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unbondings.vue")
  }
]
  },
  {
    name: "account-account-transactions",
    path: "transactions",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue")
  }
]
  },
  {
    name: "asset",
    path: "/asset",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/asset.vue")
  },
  {
    name: assets5JxONUhLURMeta?.name,
    path: "/assets",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue")
  },
  {
    name: "assets-smart-contracts",
    path: "smart-contracts",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue")
  }
]
  },
  {
    name: "block-block",
    path: "/block/:block()",
    meta: _91block_93ND4OLRWCUwMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue")
  },
  {
    name: "blocks",
    path: "/blocks",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue")
  },
  {
    name: "code-code",
    path: "/code/:code()",
    meta: _91code_93NM6XvLsoXVMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue")
  },
  {
    name: "codes",
    path: "/codes",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/codes.vue")
  },
  {
    name: "community-spend-pool",
    path: "/community-spend-pool",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue")
  },
  {
    name: _91contract_93vTnbrrqMqsMeta?.name,
    path: "/contract/:contract()",
    meta: _91contract_93vTnbrrqMqsMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue"),
    children: [
  {
    name: "contract-contract-balances",
    path: "balances",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue")
  },
  {
    name: "contract-contract-cw20balances",
    path: "cw20balances",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue")
  },
  {
    name: "contract-contract",
    path: "",
    meta: indexCynXTUk3NKMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue")
  },
  {
    name: "contract-contract-init",
    path: "init",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue")
  },
  {
    name: "contract-contract-token-holders",
    path: "token-holders",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue")
  }
]
  },
  {
    name: "contracts",
    path: "/contracts",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contracts.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: env4srMXunChyMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/env.vue")
  },
  {
    name: "featured-dapps",
    path: "/featured-dapps",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/featured-dapps.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance46QiZtYHjXMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue")
  },
  {
    name: marketsjMVx5f8CF2Meta?.name,
    path: "/markets",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue"),
    children: [
  {
    name: "markets-funding-rate-history",
    path: "funding-rate-history",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue")
  },
  {
    name: "markets",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue")
  },
  {
    name: "markets-realtime-funding-rate",
    path: "realtime-funding-rate",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue")
  },
  {
    name: "markets-spot-marketId",
    path: "spot/:marketId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-trading-rules",
    path: "trading-rules",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue")
  },
  {
    name: "markets-futures-marketId",
    path: "/futures/:marketId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-perpetual-marketId",
    path: "/perpetual/:marketId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue")
  },
  {
    name: "markets-binary-options-marketId",
    path: "/binary-options/:marketId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/spot/[marketId].vue")
  }
]
  },
  {
    name: "raw-tx",
    path: "/raw-tx",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue")
  },
  {
    name: trade_45historyITNpc6K06dMeta?.name,
    path: "/trade-history",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue"),
    children: [
  {
    name: "trade-history-derivatives-tradeId",
    path: "derivatives/:tradeId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue")
  },
  {
    name: "trade-history-derivatives",
    path: "derivatives",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue")
  },
  {
    name: "trade-history",
    path: "",
    meta: index5cBaxeZBEYMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue")
  },
  {
    name: "trade-history-spot-tradeId",
    path: "spot/:tradeId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue")
  },
  {
    name: "trade-history-spot",
    path: "spot",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue")
  }
]
  },
  {
    name: _91transaction_93vWk3ruUw4qMeta?.name,
    path: "/transaction/:transaction()",
    meta: _91transaction_93vWk3ruUw4qMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue"),
    children: [
  {
    name: "transaction-transaction-event-logs",
    path: "event-logs",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue")
  },
  {
    name: "transaction-transaction",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue")
  }
]
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue")
  }
]