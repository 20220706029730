export default defineNuxtRouteMiddleware((to, from) => {
  const appStore = useAppStore()

  const [fromPathMatch] = from.matched
  const [toPathMatch] = to.matched

  /**
   * Prevent nested tab changes from being recorded as previous page
   **/
  if (fromPathMatch?.path === toPathMatch?.path) {
    return
  }

  appStore.$patch({
    previousPath: from?.path
  })
})
