export enum AccountSubPage {
  IndexPage = 'account-account',
  CW20BalancePage = 'account-account-cw20balances',
  TransactionsPage = 'account-account-transactions',
  StakingPage = 'account-account-staking',
  StakingUnbondingPage = 'account-account-staking-unbondings',
  StakingRedelegationsPage = 'account-account-staking-redelegations'
}

export enum ContractSubPage {
  IndexPage = 'contract-contract',
  BalancePage = 'contract-contract-balances',
  CW20BalancePage = 'contract-contract-cw20balances',
  InitMsgPage = 'contract-contract-init',
  TokenHoldersPage = 'contract-contract-token-holders'
}

export enum TransactionSubPage {
  IndexPage = 'transaction-transaction',
  EventLogsPage = 'transaction-transaction-event-logs'
}

export enum BlockSubPage {
  IndexPage = 'block-block'
}

export enum CodeSubPage {
  IndexPage = 'code-code'
}

export enum MarketsSubPage {
  SpotMarketPage = 'markets-spot-marketId',
  TradingRulesPage = 'markets-trading-rules',
  FuturesMarketPage = 'markets-futures-marketId',
  PerpetualMarketPage = 'markets-perpetual-marketId',
  BinaryOptionsPage = 'markets-binary-options-marketId',
  FundingRateHistoryPage = 'markets-funding-rate-history',
  RealtimeFundingRateSubPage = 'markets-realtime-funding-rate'
}

export enum TradeHistorySubPage {
  IndexPage = 'trade-history',
  SpotsPage = 'trade-history-spot',
  SpotDetailsPage = 'trade-history-spot-tradeId',
  DerivativesPage = 'trade-history-derivatives',
  DerivativesDetailsPage = 'trade-history-derivatives-tradeId'
}

export enum AssetsSubPage {
  IndexPage = 'assets',
  SmartContractsPage = 'assets-smart-contracts'
}

export enum Pages {
  Index = 'index',
  Codes = 'codes',
  Blocks = 'blocks',
  Assets = 'assets',
  Markets = 'markets',
  Contracts = 'contracts',
  Maintenance = 'maintenance',
  Transactions = 'transactions',
  FeatureDapps = 'featured-dapps',
  CommunitySpendPool = 'community-spend-pool'
}
