import { IS_DEVNET, IS_TESTNET } from '@shared/utils/constant'
import gitVersion from './gitVersion.json'
import devnetDenoms from '@/app/json/denoms/devnet.json'
import testnetDenoms from '@/app/json/denoms/testnet.json'
import mainnetDenoms from '@/app/json/denoms/mainnet.json'
import devnetTokens from '@/app/json/tokens/devnet.json'
import testnetTokens from '@/app/json/tokens/testnet.json'
import mainnetTokens from '@/app/json/tokens/mainnet.json'
import devnetValidators from '@/app/json/validators/devnet.json'
import testnetValidators from '@/app/json/validators/testnet.json'
import mainnetValidators from '@/app/json/validators/mainnet.json'

export const getDenoms = () => {
  if (IS_DEVNET) {
    return Object.keys(devnetDenoms)
  }

  if (IS_TESTNET) {
    return Object.keys(testnetDenoms)
  }

  return Object.keys(mainnetDenoms)
}

export const getTokens = () => {
  if (IS_DEVNET) {
    return devnetTokens
  }

  if (IS_TESTNET) {
    return testnetTokens
  }

  return mainnetTokens
}

export const getValidators = () => {
  if (IS_DEVNET) {
    return devnetValidators
  }

  if (IS_TESTNET) {
    return testnetValidators
  }

  return mainnetValidators
}

export const gitBuild = () => {
  return (
    gitVersion || {
      branch: 'master',
      tag: 'v0.0.0',
      gitTagLink: '',
      logs: []
    }
  )
}

export const tokens = getTokens()
export const tradableDenoms = getDenoms()
export const validators = getValidators()
