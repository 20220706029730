export default {
  assets: {
    allAssets: 'All Assets',
    adapterAddress: 'Adapter Address',
    creatorAddress: 'Creator Address',
    assetDetail: 'Asset Detail',
    assets: 'Assets',
    assetsOnInj: 'Assets on Injective Chain',
    assetsOnSmartContracts: 'Assets on Smart Contracts',
    assetType: 'Asset Type',
    contractAddress: 'Contract Address',
    currentSupply: 'Current Supply',
    currentSupplyTimesPrice: 'Current Supply times Price',
    decimal: 'Decimal',
    denom: 'Denom',
    insuranceFund: 'Insurance Fund',
    native: 'Native',
    name: 'Name',
    overview: 'Overview',
    price: 'Price',
    searchAssets: 'Search Assets',
    token: 'Token',
    tokensOnInjective: 'Number of tokens circulating on Injective',
    type: 'Type',
    value: 'Value',
    injective: 'Injective',
    totalAssets: 'Total Assets',
    smartContracts: 'Smart Contracts',
    theAmountDisplayedMayNotBeInTheCorrectScale:
      'The amount displayed may not be in the correct scale',
    includeUnknownTokens: 'Include Unknown Tokens',
    submitTokenInfo: 'Submit Token Info',
    submitTokenMetadata: 'Submit Token Metadata',
    tokenVerification: 'Token Verification',
    theInfoOfTokenExternalVerification:
      'The name of this token is based on information found on its source network.',
    admin: 'Admin',
    adminNote: 'Minter address has the ability to increase supply'
  }
}
