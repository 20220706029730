import { defineStore } from 'pinia'
import { DEFAULT_NETWORK } from '@/app/utils/constant'
import { NetworkType } from '@/types/network'
import { SearchStatusType, NetworkErrorType } from '@/types/enum'

type AppStoreState = {
  previousPath: string
  network: NetworkType
  searchStatus?: SearchStatusType
  searchParam?: number | string
  networkErrorTypes: NetworkErrorType[]
}

const initialStateFactory = () => ({
  previousPath: '',
  network: DEFAULT_NETWORK,
  searchStatus: undefined,
  searchParam: undefined,
  networkErrorTypes: []
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  actions: {
    //
  }
})
