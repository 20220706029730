import { MAINTENANCE_ENABLED } from '@/app/utils/constant'
import { Pages } from '@/types'

export default defineNuxtRouteMiddleware((to) => {
  const isDevMode = to.query?.devMode === 'true'

  if (to.name !== Pages.Maintenance && MAINTENANCE_ENABLED && !isDevMode) {
    return navigateTo({ name: Pages.Maintenance })
  }

  if (to.name === Pages.Maintenance && (!MAINTENANCE_ENABLED || isDevMode)) {
    return navigateTo({ name: Pages.Index, query: to.query })
  }
})
