import validate from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/home/runner/work/injective-explorer/injective-explorer/middleware/maintenance.global.ts";
import previous_45page_45global from "/home/runner/work/injective-explorer/injective-explorer/middleware/previous-page.global.ts";
import trailing_45slash_45global from "/home/runner/work/injective-explorer/injective-explorer/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  previous_45page_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-search": () => import("/home/runner/work/injective-explorer/injective-explorer/middleware/account-search.ts"),
  "block-search": () => import("/home/runner/work/injective-explorer/injective-explorer/middleware/block-search.ts"),
  "contract-search": () => import("/home/runner/work/injective-explorer/injective-explorer/middleware/contract-search.ts"),
  "transaction-search": () => import("/home/runner/work/injective-explorer/injective-explorer/middleware/transaction-search.ts"),
  "wasm-code-search": () => import("/home/runner/work/injective-explorer/injective-explorer/middleware/wasm-code-search.ts")
}