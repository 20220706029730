import {
  StateTree,
  PiniaPluginContext,
  SubscriptionCallback,
  SubscriptionCallbackMutationPatchObject
} from 'pinia'
import { LocalStorage } from '@injectivelabs/utils'
import { NETWORK } from '@shared/utils/constant'
import { isThrownException, ThrownException } from '@injectivelabs/exceptions'
import { defineNuxtPlugin } from '#imports'
import { getNetworkErrorFromStoreActionType } from '@/app/utils/network'

const localStorage: LocalStorage = new LocalStorage(
  `inj-explorer-v3-${NETWORK || 'mainnet'}`
)

const stateToPersist = {
  app: {
    previousPath: ''
  }
} as Record<string, Record<string, any>>

const actionsThatCheckForNetworkError = [
  'account/fetchCW20BalancesNoThrow',
  'account/fetchTransactions',
  'account/fetchSubaccounts',
  'account/fetchAllSubaccountBalancesWithPrices',
  'bank/fetchBalances',
  'block/fetchBlock',
  'contract/fetchContract',
  'contract/fetchCW20AccountBalancesWithToken',
  'contract/fetchCW20AccountBalancesTotalCount',
  'contract/fetchContracts',
  'contract/fetchContractsTotalCount',
  'contract/fetchContractTransactions',
  'network/fetchBlocks',
  'network/fetchBlocksTotalCount',
  'network/fetchTransactions',
  'network/fetchTransactionsTotalCount',
  'transaction/fetchTransaction',
  'spendPool/fetchCommunitySpendPool',
  'wasmCode/fetchCode',
  'wasmCode/fetchCodes',
  'wasmCode/fetchCodeTotalCount'
]

const actionsThatThrowErrors = [] as string[]

const persistState = (
  mutation: SubscriptionCallbackMutationPatchObject<StateTree>,
  state: StateTree
) => {
  if (!stateToPersist[mutation.storeId]) {
    return
  }

  const keysToPersist = Object.keys(stateToPersist[mutation.storeId])
  const shouldPersistState =
    keysToPersist.length > 0 &&
    Object.keys(mutation.payload).some((key) => {
      return keysToPersist.includes(key)
    })

  if (!shouldPersistState) {
    return
  }

  const updatedState = keysToPersist.reduce(
    (stateObj, key) => ({
      ...stateObj,
      [key]: state[key]
    }),
    {}
  )

  const existingState = (localStorage.get('state') || {}) as any

  localStorage.set('state', {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: {
      ...state,
      ...updatedState
    }
  })
}

function piniaStoreSubscriber({ store }: PiniaPluginContext) {
  const localState = localStorage.get('state') as any
  const appStore = useAppStore()
  const { $onError } = useNuxtApp()

  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] }
  }

  store.$subscribe(persistState as SubscriptionCallback<StateTree>)

  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      const type = `${$id}/${name}`

      if (actionsThatCheckForNetworkError.includes(type)) {
        const networkErrorTypes = appStore.networkErrorTypes
        const networkErrorType = getNetworkErrorFromStoreActionType(type)

        appStore.$patch({
          networkErrorTypes: networkErrorTypes.filter(
            (type) => type !== networkErrorType
          )
        })
      }
    })

    onError((error) => {
      const type = `${$id}/${name}`

      if (actionsThatCheckForNetworkError.includes(type)) {
        const networkErrorTypes = appStore.networkErrorTypes
        const networkErrorType = getNetworkErrorFromStoreActionType(type)

        appStore.$patch({
          networkErrorTypes: [...networkErrorTypes, networkErrorType]
        })
      }

      if (
        actionsThatThrowErrors.includes(type) &&
        isThrownException(error as Error)
      ) {
        $onError(error as unknown as ThrownException)
      }
    })
  })
}

export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber)
  }
)
