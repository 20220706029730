import { I18nMessageFunction } from './../../../types'

export default {
  transaction: {
    txs: 'Txs',
    fee: 'Fee',
    type: 'Type',
    hash: 'Hash',
    time: 'Time',
    memo: 'Memo',
    view: 'View',
    items: 'Items',
    height: 'Height',
    events: 'Events',
    sender: 'Sender',
    newTxs: 'New Txs',
    txHash: 'Tx Hash',
    message: 'Message',
    summary: 'Summary',
    txnHash: 'Txn Hash',
    messages: 'Messages',
    errorLog: 'Error Log',
    messageType: 'Message Type',
    rawTransaction: 'Raw Transaction',
    newTransactions: 'New Transactions',
    transactionHash: 'Transaction Hash',
    transactionDetails: 'Transaction details',
    gasUsedAndRequested: 'Gas (Used/Requested)',
    noTransactionsFound: 'No transactions found.',

    voter: 'Voter',
    signer: 'Signer',
    details: 'Details',
    proposer: 'Proposer',
    receiver: 'Receiver',
    depositor: 'Depositor',
    validator: 'Validator',
    subaccount: 'Subaccount',
    orchestrator: 'Orchestrator',

    hashInvalidTitle: 'Oops! An invalid Tx hash has been entered:',
    hashInvalidDescription: 'Please re-enter the transaction hash correctly.',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, the following transaction was not found on ${named('network')}`,
    notFoundDescription:
      'The Injective Explorer only provides an overview of the current state of the blockchain such as your transaction status. The Injective Explorer does NOT control any transaction.',

    notFoundDescriptionPoint1:
      '1) If you have just submitted a transaction, please wait at least 30 seconds before refreshing this page.',
    notFoundDescriptionPoint2:
      '2) Your transaction could still be in the TX pool of a different node, waiting to be broadcasted.',
    notFoundDescriptionPoint3:
      '3) If the network is busy, it can take some time for your transaction to appear.',
    notFoundDescriptionPoint4:
      '4) If your transaction still does not appear after 1 hour, please check with your sender or exchange/wallet/transaction provider for additional information.',

    proposal: {
      proposal: 'proposal',
      yes: 'yes',
      abstain: 'abstain',
      no: 'no',
      noWithVeto: 'no with veto'
    },

    summaryType: {
      MsgBatchAllCancelDerivativeOrders: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([named('sender'), ' cancelled all derivative orders in: ']),
      MsgBatchAllCancelDerivativeOrderItem: ({ named }: I18nMessageFunction) =>
        `${named('ticker')}`,
      MsgBatchAllCancelSpotOrders: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([named('sender'), ' cancelled all spot orders in: ']),
      MsgBatchAllCancelSpotOrderItem: ({ named }: I18nMessageFunction) =>
        `the ${named('ticker')} spot market`,
      MsgBatchCancelDerivativeOrders: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' cancelled a batch of derivative orders'
        ]),
      MsgBatchCancelDerivativeOrderItem: ({ named }: I18nMessageFunction) =>
        `${named('ticker')} with the following order hash: ${named(
          'orderHash'
        )}`,
      MsgBatchCreateDerivativeLimitOrders: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created a batch of derivative limit orders'
        ]),
      MsgBatchCreateDerivativeLimitOrderItem: ({
        named
      }: I18nMessageFunction) =>
        `${named('orderType')} for ${named('amount')} ${named(
          'baseSymbol'
        )} at ${named('price')} ${named('quoteSymbol')} in the ${named(
          'ticker'
        )} derivative market'`,
      MsgBatchCreateSpotLimitOrders: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([named('sender'), ' created a batch of spot limit orders']),
      MsgBatchCreateSpotLimitOrderItem: ({ named }: I18nMessageFunction) => `
        ${named('orderType')} for ${named('amount')} ${named(
          'baseSymbol'
        )} at ${named('price')} ${named('quoteSymbol')} in the ${named(
          'ticker'
        )} spot market`,
      MsgExecuteContractCompatSwap: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' swapping ',
          named('inputAmount'),
          ' ',
          named('inputSymbol'),
          ' for ',
          named('outputAmount'),
          ' ',
          named('outputSymbol')
        ]),
      MsgBatchCancelSpotOrders: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([named('sender'), ' cancelled a batch of spot orders']),
      MsgBatchCancelSpotOrderContent: ({ named }: I18nMessageFunction) =>
        `order hash ${named('orderHash')} in the ${named(
          'ticker'
        )} spot market`,
      MsgBeginRedelegate: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' redelegated ',
          named('amount'),
          ' ',
          named('symbol'),
          ' from ',
          named('sourceValidator'),
          ' to ',
          named('destinationValidator')
        ]),
      MsgBid: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' submitted a bid of ',
          named('amount'),
          ' ',
          named('symbol'),
          ' in round ',
          named('round')
        ]),
      MsgCancelDerivativeOrder: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' cancelled order hash ',
          named('orderHash'),
          ' in the ',
          named('ticker'),
          ' derivative market'
        ]),
      MsgCancelSpotOrder: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' cancelled order hash ',
          named('orderHash'),
          ' in the ',
          named('ticker'),
          ' spot market'
        ]),
      MsgCreateDerivativeMarketOrder: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created a market ',
          named('orderType'),
          ' for ',
          named('amount'),
          ' ',
          named('baseSymbol'),
          ' at ',
          named('price'),
          ' ',
          named('quoteSymbol'),
          ' in the ',
          named('ticker'),
          'derivative market'
        ]),
      MsgCreateDerivativeLimitOrder: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created a limit ',
          named('orderType'),
          ' for ',
          named('amount'),
          ' ',
          named('baseSymbol'),
          ' at ',
          named('price'),
          ' ',
          named('quoteSymbol'),
          ' in the ',
          named('ticker'),
          ' derivative market'
        ]),
      MsgCreateSpotLimitOrder: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created a limit ',
          named('orderType'),
          ' for ',
          named('amount'),
          ' ',
          named('baseSymbol'),
          ' at ',
          named('price'),
          ' ',
          named('quoteSymbol'),
          ' in the ',
          named('ticker'),
          'spot market'
        ]),
      MsgCreateSpotMarketOrder: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created a market ',
          named('orderType'),
          ' for ',
          named('amount'),
          ' ',
          named('baseSymbol'),
          ' at ',
          named('price'),
          ' ',
          named('quoteSymbol'),
          ' in the ',
          named('ticker'),
          'spot market'
        ]),
      MsgCreateInsuranceFund: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' created an insurance fund with an initial deposit of ',
          named('amount'),
          ' ',
          named('symbol'),
          ' for the ',
          named('ticker'),
          ' market'
        ]),
      MsgCreateValidator: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'Validator ',
          named('moniker'),
          ' has been created with the address ',
          named('validator')
        ]),
      MsgConfirmBatch: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([named('orchestrator'), ' confirmed a batch request']),
      MsgDelegate: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' delegated ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('validator')
        ]),
      MsgDeposit: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' deposited ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to subaccount ',
          named('subaccount')
        ]),
      MsgDepositClaim: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' deposited ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver'),
          ' on Injective'
        ]),
      MsgEditValidator: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('validator'),
          ' modified ',
          named('moniker'),
          ' validator details'
        ]),
      MsgExternalTransfer: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' transferred ',
          named('amount'),
          ' ',
          named('symbol'),
          ' from subaccount ',
          named('fromSubaccount'),
          ' to subaccount ',
          named('toSubaccount'),
          named('toAuctionPool')
        ]),
      MsgGovDeposit: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('depositor'),
          ' deposited ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('proposal')
        ]),
      MsgIncreasePositionMargin: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' increased position margin by ',
          named('amount'),
          ' ',
          named('symbol'),
          ' for the ',
          named('ticker'),
          ' market from subaccount ',
          named('from'),
          ' to subaccount ',
          named('to')
        ]),
      MsgInstantSpotMarketLaunch: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' launched the ',
          named('ticker'),
          ' spot market instantly'
        ]),
      MsgLiquidatePosition: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' liquidated a position in the ',
          named('ticker'),
          ' market that belonged to the subaccount ',
          named('subaccount')
        ]),
      MsgRecvPacket: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' deposited ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver'),
          ' from ',
          named('network')
        ]),
      MsgRequestBatch: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([named('orchestrator'), 'sent a batch request']),
      MsgRequestRedemption: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' requested a redemption of ',
          named('amount'),
          ' ',
          named('symbol'),
          ' from the ',
          named('ticker'),
          ' insurance fund'
        ]),
      MsgSend: ({ interpolate, named }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' sent ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver')
        ]),
      MsgMultiSend: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' ',
          named('direction'),
          ' ',
          named('amount'),
          ' ',
          named('symbol')
        ]),
      MsgSendToEth: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' withdrew ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver'),
          ' on Ethereum'
        ]),
      MsgSetOrchestratorAddresses: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' set the orchestrator address to ',
          named('orchestrator')
        ]),
      MsgTransfer: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' withdrew ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver'),
          ' from ',
          named('network')
        ]),
      MsgSubaccountTransfer: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' transferred ',
          named('amount'),
          ' ',
          named('symbol'),
          ' from subaccount ',
          named('fromSubaccount'),
          ' to subaccount ',
          named('toSubaccount')
        ]),
      MsgSubmitProposal: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('proposer'),
          ' submitted a proposal with a deposit of ',
          named('amount'),
          ' ',
          named('symbol')
        ]),
      MsgSubmitProposalWithoutAmount: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([named('proposer'), ' submitted a proposal']),
      MsgUndelegate: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' undelegated ',
          named('amount'),
          ' ',
          named('symbol'),
          'from ',
          named('validator')
        ]),
      MsgUnderwrite: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' underwrote ',
          named('amount'),
          ' ',
          named('symbol'),
          'in the ',
          named('ticker'),
          ' insurance fund'
        ]),
      MsgUnjail: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([named('validator'), ' sent an unjail message']),
      MsgValsetConfirm: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([named('orchestrator'), ' confirmed the valset']),
      MsgTimeout: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' deposited ',
          named('amount'),
          ' ',
          named('symbol'),
          ' to ',
          named('receiver'),
          ' on ',
          named('network')
        ]),
      MsgVote: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('voter'),
          ' voted ',
          named('option'),
          ' for ',
          named('proposal')
        ]),
      MsgWithdraw: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' withdrew ',
          named('amount'),
          ' ',
          named('symbol'),
          ' from subaccount ',
          named('subaccount')
        ]),
      MsgWithdrawDelegatorReward: ({
        named,
        interpolate
      }: I18nMessageFunction) =>
        interpolate([
          named('sender'),
          ' claimed rewards from ',
          named('validator')
        ])
    },

    eventLogs: {
      title: 'Event Logs',
      logs: 'logs'
    },

    claimId: 'Claim #',
    claimIdNote:
      'Multiple MsgDepositClaim transactions are executed for an Ethereum deposit. Each deposit is identified by an unique claimId.',

    toAuctionPool: ' as a contribution to the next auction pool'
  }
}
