export * from './page'

export enum SearchStatusType {
  Valid = 'valid',
  BlockInvalid = 'blockInvalid',
  BlockNotFound = 'blockNotFound',
  AccountInvalid = 'accountInvalid',
  AccountNotFound = 'accountNotFound',
  ContractInvalid = 'contractInvalid',
  WasmCodeInvalid = 'wasmCodeInvalid',
  WasmCodeNotFound = 'wasmCodeNotFound',
  ContractNotFound = 'contractNotFound',
  TransactionNotFound = 'transactionNotFound',
  TransactionHashInvalid = 'invalidTransactionHash'
}

export enum GlobalSearchType {
  Ibc = 'ibc',
  Block = 'block',
  Native = 'native',
  Account = 'account',
  BlockHash = 'blockHash',
  Subaccount = 'subaccount',
  Transaction = 'transaction',
  EtherAccount = 'etherAccount',
  BridgedERC20 = 'bridgedERC20',
  InjDomainName = 'injDomainName',
  InjBonfidaDomainName = 'injBonfidaDomainName'
}

export enum AccountTab {
  Staking = 'staking',
  Balance = 'balances',
  Transaction = 'transactions',
  CW20Balances = 'cw20balances'
}

export enum SmartContractTab {
  Balance = 'balance',
  CW20Balances = 'cw20balances',
  Transactions = 'transactions',
  TokenHolders = 'tokenHolders',
  InitMsg = 'initMsg'
}

export enum NetworkErrorType {
  AssetDetailsPage = 'AssetDetailsPage',
  BlockDetailsPage = 'blockDetailsPage',
  BlocksPageBlocks = 'blocksPageBlocks',
  AccountDetailsPage = 'AccountDetailsPage',
  AccountPageStaking = 'accountPageStaking',
  ContractDetailsPage = 'contractDetailsPage',
  GenericNoRecordsFound = 'genericNoRecordsFound',
  ContractsPageContracts = 'contractsPageContracts',
  TransactionDetailsPage = 'transactionDetailsPage',
  AccountPageTransactions = 'accountPageTransactions',
  AccountPageBankBalances = 'accountPageBankBalances',
  AccountPageCW20Balances = 'accountPageCW20Balances',
  ContractPageTransactions = 'contractPageTransactions',
  ContractsPageAccountsBalance = 'contractsPageAccountsBalance',
  TransactionsPageTransactions = 'transactionsPageTransactions',
  AccountPageSubaccountBalances = 'accountPageSubaccountBalances',
  WasmCodeDetailsPage = 'wasmCodeDetailsPage',
  WasmCodePageCodes = 'wasmCodePageCodes',
  TradeHistory = 'tradeHistory',
  CommunitySpendPool = 'communitySpendPool'
}

export enum AssetSelectorType {
  All = 'all',
  Ibc = 'ibc',
  Native = 'native',
  Cw20 = 'cosmWasm',
  BridgedERC20 = 'bridgedERC20'
}

export enum MarketsTab {
  MarketsInfo = 'marketsinfo',
  TradingRules = 'tradingrules',
  FundingRateHistory = 'fundingratehistory',
  RealtimeFundingRate = 'realtimefundingrate'
}

export enum StakingTab {
  Unbondings = 'unbondings',
  Delegations = 'delegations',
  Redelegations = 'redelegations'
}

export enum AssetTab {
  Injective = 'injective',
  SmartContracts = 'smartcontracts'
}

export enum TransactionTab {
  Message = 'message',
  EventLogs = 'eventlogs'
}

export enum MarketType {
  All = 'all',
  Spot = 'spot',
  Derivative = 'derivative',
  Futures = 'futures'
}

export enum RealtimeFundingRateHeaderType {
  Market = 'market',
  FundingRate = 'fundingRate'
}

export enum MarketInfoHeaderType {
  Market = 'market',
  Volume = 'volume'
}

export enum TradingRulesHeaderType {
  Market = 'market'
}

export enum TradeHistoryHeaderType {
  Time = 'time'
}

export enum AssetHeaderType {
  Value = 'value',
  Token = 'market',
  Price = 'volume',
  Supply = 'Supply'
}

export enum FundingRateHistoryInterval {
  TwelveHours = 'twelveHours',
  TwentyFourHours = 'twentyFourHours'
}

export enum MetaType {
  Code = 'Code',
  Block = 'Block',
  Codes = 'Codes',
  Blocks = 'Blocks',
  Markets = 'Markets',
  Contract = 'Contract',
  Contracts = 'Contracts',
  Transaction = 'Transaction',
  Transactions = 'Transactions'
}

export enum PaginationState {
  InvalidQuery = 'invalid-query',
  QueryPageExist = 'query-page-exist',
  QueryMoreThanTotalPage = 'query-more-than-total-page'
}

export enum GlobalEvents {
  Status = 'status'
}

export enum DappCategory {
  Dex = 'DEX',
  DeFi = 'DeFi',
  Tools = 'Tools',
  NFTFi = 'NFTFi',
  SocialFi = 'SocialFi',
  AllCategory = 'All Category'
}

export enum PageCategory {
  Trading = 'Trading',
  Ecosystem = 'Ecosystem',
  Blockchain = 'Blockchain',
  Developers = 'Developers'
}

export enum TradeHistorySearchType {
  InjectiveAddress = 'account',
  SubaccountId = 'subaccountId'
}

export enum TradeHistoryField {
  Search = 'search',
  EndTime = 'endTime',
  Address = 'address',
  MarketId = 'marketId',
  Direction = 'direction',
  StartTime = 'startTime'
}

export enum TransactionsFilterField {
  StartTime = 'startTime',
  EndTime = 'endTime',
  MsgType = 'type',
  Status = 'status'
}

export enum ContractsFilterField {
  Search = 'search'
}

export enum SpendPoolFilterField {
  StartTime = 'startTime',
  EndTime = 'endTime',
  Side = 'side',
  Address = 'address'
}

export enum SpendPoolFilterSide {
  From = 'from',
  To = 'to'
}

export enum WasmSwapExecuteMessageType {
  SwapMinOutput = 'swap_min_output',
  SwapExactOutput = 'swap_exact_output'
}

export enum WasmExecuteMessageType {
  Swap = 'Swap'
}

export enum InjectionKey {
  SubaccountIdKey = 'subaccountIdKey',
  IsBalanceBreakdownEnabledKey = 'isBalanceBreakdownEnabledKey'
}

export enum StatusKey {
  TxsInitStatus = 'TxsInitStatus',
  SpotInitStatus = 'SpotInitStatus',
  BlocksInitStatus = 'BlocksInitStatus',
  ParamsInitStatus = 'ParamsInitStatus',
  NetworkInitStatus = 'NetworkInitStatus',
  InjSupplyInitStatus = 'InjSupplyInitStatus',
  PortfolioInitStatus = 'PortfolioInitStatus',
  UsdPricesInitStatus = 'UsdPricesInitStatus',
  DerivativeInitStatus = 'DerivativeInitStatus',
  AccountStakingInitStatus = 'AccountStakingInitStatus',
  AccountPositionsInitStatus = 'AccountPositionsInitStatus',
  isBalanceBreakdownEnabledKey = 'isBalanceBreakdownEnabledKey'
}
